<template>
    <div class="col-sm-3 pe-4 border-end">
        <div class="btn btn-primary text-light mt-3 w-100" data-bs-toggle="modal" data-bs-target="#prescriptionModal"
             @click="openModal('C/C', 'cc')">
            C/C:
        </div>
        <div class="btn btn-primary text-light mt-3 w-100" data-bs-toggle="modal" data-bs-target="#prescriptionModal"
             @click="openModal('O/E', 'oe')">
            O/E:
        </div>
        <div class="btn btn-primary text-light mt-3 w-100" data-bs-toggle="modal" data-bs-target="#prescriptionModal"
             @click="openModal('X-Ray', 'x-ray')">
            X-Ray:
        </div>
        <div class="d-flex justify-content-between align-items-center mt-3">
            <label for="opg" class="btn btn-primary text-light w-100">
                OPG:
            </label>
            <div class="custom-checkbox ms-5">
                <input v-model="$parent.form.opg_checked" id="opg" type="checkbox">
                <label for="opg"></label>
            </div>
        </div>
        <div class="btn btn-primary text-light mt-3 w-100" data-bs-toggle="modal" data-bs-target="#prescriptionModal"
             @click="openModal('Adv', 'adv')">
            Adv:
        </div>
        <div class="btn btn-primary text-light mt-3 w-100" data-bs-toggle="modal" data-bs-target="#prescriptionModal"
             @click="openModal('Treatment Plan', 'treatment_plan')">
            Treatment Plan:
        </div>
        <div class="mt-3 w-100">
            <div class="d-flex align-items-center">
                <span class="w-75">Inv: </span>
                <input v-model="$parent.form.inv" type="text" class="form-control ms-2">
            </div>
        </div>
        <div class="mt-3 w-100">
            <div class="d-flex align-items-center">
                <span class="w-75">BP: </span>
                <input v-model="$parent.form.bp" type="text" class="form-control ms-2">
            </div>
        </div>
        <div class="mt-3 w-100">
            <div class="d-flex align-items-center">
                <span class="w-75">Diabetes:</span>
                <input v-model="$parent.form.diabetes" type="text" class="form-control ms-2">
            </div>
        </div>
        <div class="mt-3 w-100">
            <div class="d-flex align-items-center">
                <span class="w-75">Temp:</span>
                <input v-model="$parent.form.temp" type="text" class="form-control ms-2">
            </div>
        </div>
        <div class="mt-3 w-100">
            <div class="d-flex align-items-center">
                <span class="w-50">Next Visit:</span>
                <date-picker value-type="YYYY-MM-DD HH:mm:ss" v-model="$parent.form.next_visit"
                             format="DD-MMM-YYYY hh:mm A" type="datetime" placeholder="Select Next Visit"/>
            </div>
        </div>
        <div class="modal fade" id="prescriptionModal" data-bs-backdrop="static" data-bs-keyboard="false"
             tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-body">
                        <form @submit.prevent="itemSave()">
                            <div class="align-items-center d-flex justify-content-between mb-3">
                                <h5>{{ selected_title }}</h5>
                                <div class="d-flex flex-wrap w-25 position-relative">
                                    <div class="position-absolute top-50 w-100 border-dark border-top"></div>
                                    <div class="position-absolute border-start border-dark h-100 start-50"></div>
                                    <div class="w-50 p-2">
                                        <input v-model="form.top_left" type="text" class="form-control">
                                    </div>
                                    <div class="w-50 p-2">
                                        <input v-model="form.top_right" type="text" class="form-control">
                                    </div>
                                    <div class="w-50 p-2">
                                        <input v-model="form.bottom_left" type="text" class="form-control">
                                    </div>
                                    <div class="w-50 p-2">
                                        <input v-model="form.bottom_right" type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <textarea v-model="form.comment" cols="30" rows="10" class="form-control"
                                      placeholder="Write here..."></textarea>
                            <div class="d-flex gap-4 justify-content-center">
                                <input type="submit" value="Save" class="bg-primary form-control mt-4 text-light w-25">
                                <div type="button" id="btn-modal-close"
                                     class="bg-light form-control mt-4 text-center w-25"
                                     data-bs-dismiss="modal" aria-label="Close">Cancel
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name      : "LeftSection",
    components: {DatePicker},
    data      : () => ({
        selected_title: '',
        selected_name : '',
        form          : {
            type        : '',
            top_left    : '',
            top_right   : '',
            bottom_left : '',
            bottom_right: '',
            comment     : ''
        }
    }),
    methods   : {
        formReset() {
            this.form = {
                type        : '',
                top_left    : '',
                top_right   : '',
                bottom_left : '',
                bottom_right: '',
                comment     : ''
            }
        },
        openModal(title, type) {
            let obj = this.$parent.form.items.find(item => item.type == type);
            if (typeof obj == 'undefined') {
                this.formReset()
            } else {
                this.form = obj;
            }
            this.selected_title = title;
            this.selected_name  = type;
        },
        itemSave() {
            this.form.type = this.selected_name;
            let items      = this.$parent.form.items;
            const objIndex = items.findIndex(obj => obj.type === this.selected_name);
            if (objIndex === 0) {
                items[objIndex] = this.form
            } else {
                items.push(this.form);
            }
            document.querySelectorAll("#btn-modal-close")[0].click();
        }
    }
}
</script>
