<template>
    <div id="patient-create-section" class="create-section mt-5">
        <div class="container">
            <div class="row fw-bold">
                <div class="col-md-4 mb-3">
                    <div class="d-flex align-items-center justify-content-center">
                        <span class="me-2">Date: </span>
                        <DatePicker class="w-100" v_model="date"/>
                    </div>
                </div>
                <div class="col-md-8 mb-3">
                    <div class="d-flex align-items-baseline justify-content-between">
                        <span style="white-space: pre;">Doctor Name: </span>
                        <div class="w-100 ms-3">
                            <drop-down :class_name="{ 'is-invalid': errors['doctor_id'] }" title="Doctor"
                                       v_model="doctor"/>
                            <div v-if="errors['doctor_id']" class="invalid-feedback">
                                {{ errors['doctor_id'][0] }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 mb-2 mb-sm-0">
                    <div class="d-flex align-items-center">
                        <span>Name: </span>
                        <input placeholder="First Name" v-model="form.patient.name" type="text"
                               class="form-control ms-2">
                        <input placeholder="Last Name" v-model="form.patient.last_name" type="text"
                               class="form-control ms-2">
                    </div>
                </div>
                <div class="col-md-4 mb-2 mb-sm-0">
                    <div class="d-flex align-items-center justify-content-sm-center">
                        <span class="me-2">Age: </span>
                        <input v-model="form.patient.date_of_year" type="text" class="form-control me-2"
                               style="width: 50px">
                        <span class="me-2">y</span>
                        <input v-model="form.patient.date_of_month" type="text" class="form-control me-2"
                               style="width: 50px">
                        <span>m </span>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="float-sm-end">Sex:
                        <span type="button" @click="form.patient.gender='Male'"
                              :class="[form.patient.gender==='Male' ? 'bg-primary text-white': 'bg-light']"
                              class="p-2 mx-1 rounded">Male</span>
                        <span type="button" @click="form.patient.gender='Female'"
                              :class="[form.patient.gender==='Female' ? 'bg-primary text-white': 'bg-light']"
                              class="p-2 mx-1 rounded">Female</span>
                        <span type="button" @click="form.patient.gender='Other'"
                              :class="[form.patient.gender==='Other' ? 'bg-primary text-white': 'bg-light']"
                              class="p-2 mx-1 rounded">Other</span>
                    </div>
                </div>
            </div>
            <div class="row border-top mt-4">
                <LeftSection/>
                <RightSection/>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import LeftSection         from "./includes/LeftSection";
import RightSection        from "./includes/RightSection";
import moment              from "moment";
import DatePicker          from "@/components/picker/DatePicker";

export default {
    name      : "Create",
    components: {DatePicker, RightSection, LeftSection},
    data      : () => ({
        editMode: false,
        form    : {
            date       : moment(new Date()).format("YYYY-MM-DD"),
            patient_id : '',
            inv        : '',
            bp         : '',
            diabetes   : '',
            temp       : '',
            opg_checked: false,
            next_visit : '',
            patient    : {
                id           : '',
                name         : '',
                last_name    : '',
                date_of_year : '',
                date_of_month: '',
                gender       : '',
            },
            doctor     : '',
            items      : [],
            medicines  : [
                {
                    medicine: '',
                    dose    : '',
                    duration: '',
                }
            ],
        },
        errors  : [],
    }),
    created   : function () {
        if (this.$route.name === 'prescriptionEdit') {
            this.edit(this.$route.params.id);
        } else if (this.$route.query.patient_id) {
            this.getPatientData(this.$route.query.patient_id);
        }
    },
    mounted() {
    },
    methods: {
        //-------CRUD------
        save(type = 'save') {
            this.form.doctor_id                  = this.form.doctor ? this.form.doctor.id : '';
            this.form.patient_id                 = this.form.patient ? this.form.patient.id : '';
            this.form.patient.assigned_doctor_id = this.form.doctor ? this.form.doctor.id : '';
            this.form.date                       = moment(this.form.date).format("YYYY-MM-DD")
            ApiService.post('/prescriptions', this.form).then((res) => {
                this.formReset();
                this.errors = [];
                if (this.$route.query.patient_id) {
                    this.getPatientData(this.$route.query.patient_id);
                }
                NotificationService.success(res.data.message);
                if (type == 'save') {
                    this.$router.push({name: 'patientList'});
                } else if (type == 'preview') {
                    this.$router.push({name: 'prescriptionPreview', params: {id: res.data.data.id}});
                }
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        edit(id) {
            this.editMode = true;
            ApiService.get(`/prescriptions/${id}`).then((res) => {
                this.form            = res.data.data;
                this.form.patient_id = res.data.data.patient.id
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        update(type = 'save') {
            this.form.doctor_id                  = this.form.doctor ? this.form.doctor.id : '';
            this.form.patient_id                 = this.form.patient ? this.form.patient.id : '';
            this.form.patient.assigned_doctor_id = this.form.doctor ? this.form.doctor.id : '';
            this.form.date                       = moment(this.form.date).format("YYYY-MM-DD")
            ApiService.update(`/prescriptions/${this.form.id}`, this.form).then((res) => {
                this.errors = [];
                NotificationService.success(res.data.message);
                if (type == 'save') {
                    this.$router.push({name: 'patientList'});
                } else if (type == 'preview') {
                    this.$router.push({name: 'prescriptionPreview', params: {id: res.data.data.id}});
                }
            }).catch(error => {
                this.errors = error.response.data.errors;
                NotificationService.error(error.response.data.message);
            })
        },
        formReset() {
            this.form = {
                date       : moment(new Date()).format("DD-MMM-YYYY"),
                patient_id : '',
                inv        : '',
                bp         : '',
                diabetes   : '',
                temp       : '',
                opg_checked: false,
                next_visit : '',
                patient    : {
                    id           : '',
                    name         : '',
                    last_name    : '',
                    date_of_year : '',
                    date_of_month: '',
                    gender       : '',
                },
                items      : [],
                medicines  : [
                    {
                        medicine: '',
                        dose    : '',
                        duration: '',
                    }
                ],
            }
        },
        //-------CRUD--------
        getPatientData(id) {
            this.editMode = false;
            ApiService.get(`/patients/${id}`, {params: {request_type: 'prescription'}}).then((res) => {
                this.form.patient    = res.data.data
                this.form.patient_id = this.form.patient.id
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        }
    },
    watch  : {
        $route(to, from) {
            this.formReset();
        }
    }
}
</script>
